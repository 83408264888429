import React from 'react'
import EventItem from '../EventItem/EventItem'
import './EventItemList.scss'
import { useTrail } from 'react-spring'

export default ({ events, category }) => {
	if (!events || !events.length) return null
	const categoryName = category
		.toString()
		.toLowerCase()
		.split(' ')
		.join('')

	const trail = useTrail(events.length, {
		config: { mass: 6, tension: 1500, friction: 150 },
		from: {
			opacity: 0,
			transform: 'translate3d(0,2.5rem,0)',
		},
		to: { opacity: 1, transform: 'translate3d(0,0,0)' },
	})

	return (
		<div
			className={'event__list'}
			aria-live={'polite'}
			id={`${categoryName}-tab`}
			role="tabpanel"
			aria-labelledby={`#${categoryName}`}
		>
			{trail.length ? (
				<ul>
					{trail.map((props, i) => (
						<EventItem
							key={`${category} ${i}`}
							event={events[i]}
							style={props}
						/>
					))}
				</ul>
			) : null}
		</div>
	)
}
