import React from 'react'
import './EventItemFilterButton.scss'
import Button from '../Button/Button'

export default ({ category, action, active }) => {
	const categoryName = category
		.toString()
		.toLowerCase()
		.split(' ')
		.join('')

	const activeName = active
		.toString()
		.toLowerCase()
		.split(' ')
		.join('')

	return (
		<Button
			text={category}
			className={`secondary event__button${
				activeName === categoryName ? ' active' : ''
			}`}
			action={action}
			role={'tab'}
			ariaSelected={'false'}
			ariaControls={`${categoryName}-tab`}
			idValue={`#${categoryName}`}
		/>
	)
}
