import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import EventItemFilter from '../EventItemFilter/EventItemFilter'
import { HeaderContext } from '../Header/Header'
import { format, isValid } from 'date-fns'
import { encodeToReadableUrl } from '../../utils/encodeToReadableUrl'

export function formatDate(date) {
	if (date !== null && typeof date !== 'undefined') {
		const newDate = new Date(date)
		if (!newDate || !isValid(newDate)) return date
		return format(newDate, 'dd/MM/yyyy')
	} else {
		return null
	}
}

export default function EventItems(props) {
	const HeaderCTX = useContext(HeaderContext)

	const {
		allWordpressWpCursus,
		allWordpressWpOverigeactiviteiten,
		alleReizen,
	} = useStaticQuery(
		graphql`
			query {
				allWordpressWpCursus {
					nodes {
						acf {
							title_course
							subject
							start_date
							end_date
							price
							category
						}
						wordpress_id
						link
					}
				}
				allWordpressWpOverigeactiviteiten {
					nodes {
						acf {
							title
							subject
							category
						}
						wordpress_id
						link
					}
				}
				alleReizen: allWordpressWpReis {
					nodes {
						acf {
							reis_id
							cursusnaam
							seizoenCursus
							intro_text
							reis_blok {
								reis_beschrijving
								reis_title
							}
							sfeer_fotos {
								alt_text
								source_url
							}
							reis_informatie_blok {
								prijs
								aantal_dagen
								datums {
									eind_datum
									gegarandeerd_of
									start_datum
								}
							}
						}
						wordpress_id
						link
					}
				}
			}
		`,
	)

	const filteredCourses = allWordpressWpCursus.nodes.filter(
		(course) =>
			course.acf.subject !==
				(HeaderCTX.winterTime ? 'Skeeleren' : 'Schaatsen') &&
			course.acf.subject !==
				(HeaderCTX.winterTime ? 'Mountainbiken' : 'Schaatsen'),
		// (HeaderCTX.winterTime ? 'Skeeleren' || 'Mountainbiken' : 'Schaatsen'),
	)
	const transformedFilteredCourses = {
		nodes: filteredCourses,
	}

	const eventItems = getAllCourses(transformedFilteredCourses)
	const otherEvents = getAllEvents(allWordpressWpOverigeactiviteiten)
	const allTrips = getAllVacations(alleReizen)

	const allActivities = eventItems.concat(otherEvents).concat(allTrips)

	function getAllCourses(allWordpressWpCursus) {
		return (
			allWordpressWpCursus.nodes
				// .filter((course) => course.subject === courseTypes)
				.map((course) => {
					return {
						name: course.acf.title_course,
						to: `${
							HeaderCTX.winterTime ? '/winter' : '/zomer'
						}/cursus/${encodeToReadableUrl(course.link)}${
							course.wordpress_id
						}`,
						price: `${course.acf.price}`,
						period: `${formatDate(
							course.acf.start_date,
						)} - ${formatDate(course.acf.end_date)}`,
						category: `${course.acf.category}`,
					}
				})
		)
	}

	function getAllEvents(allWordpressWpOverigeactiviteiten) {
		return allWordpressWpOverigeactiviteiten.nodes.map((event) => ({
			name: event.acf.title,
			to: `/activiteit/${encodeToReadableUrl(event.link)}/${
				event.wordpress_id
			}`,
			// price: `${course.acf.price}`,
			// period: `${course.acf.start_date} - ${course.acf.end_date}`,
			category: `${event.acf.category}`,
		}))
	}

	function getAllVacations(alleReizen) {
		return alleReizen.nodes.map((trip) => {
			return {
				name: `${trip.acf.reis_blok.reis_title} - ${trip.acf.reis_informatie_blok.aantal_dagen} dagen`,
				to: `/vakantie/${encodeToReadableUrl(trip.link)}/${
					trip.wordpress_id
				}`,
				period: `${trip.acf.reis_informatie_blok.datums.filter((i) => {
					return i.gegarandeerd_of === 'Gegarandeerd' && i[0]
						? formatDate(i[0].start_datum)
						: ''
				})} - ${trip.acf.reis_informatie_blok.datums.filter((i) => {
					return i.gegarandeerd_of === 'Gegarandeerd' && i[0]
						? formatDate(i[0].eind_datum)
						: ''
				})}`,
				price: `${trip.acf.reis_informatie_blok.prijs}`,
				category: `Sportvakantie`,
				type: 'Reis',
			}
		})
	}
	return <EventItemFilter events={allActivities} />
}
