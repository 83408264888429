import React from 'react'

export default ({ winter }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 33 36'}>
			<path
				fill={winter ? '#005394' : 'rgba(234, 134, 45, 1)'}
				d="M31.78 5.75A2.4 2.4 0 0030.02 5h-2.5V3.13c0-.86-.31-1.6-.92-2.21A3.01 3.01 0 0024.39 0h-1.25c-.86 0-1.6.3-2.21.92a3.01 3.01 0 00-.92 2.2V5h-7.5V3.13c0-.86-.3-1.6-.92-2.21A3.01 3.01 0 009.38 0H8.13c-.86 0-1.6.3-2.2.92A3.01 3.01 0 005 3.12V5H2.5a2.4 2.4 0 00-1.76.75A2.4 2.4 0 000 7.5v25.02c0 .68.25 1.26.74 1.76.5.5 1.08.74 1.76.74h27.52a2.4 2.4 0 001.76-.74c.49-.5.74-1.08.74-1.76V7.5a2.4 2.4 0 00-.74-1.75zM22.5 3.13a.6.6 0 01.18-.45.6.6 0 01.45-.18h1.25a.6.6 0 01.45.18.6.6 0 01.17.45v5.63a.6.6 0 01-.17.44.61.61 0 01-.45.18h-1.25a.6.6 0 01-.45-.18.6.6 0 01-.18-.44V3.13zm-15 0a.6.6 0 01.17-.45.6.6 0 01.45-.18h1.25a.6.6 0 01.45.18.6.6 0 01.18.45v5.63a.6.6 0 01-.18.44.6.6 0 01-.45.18H8.13a.6.6 0 01-.45-.18.61.61 0 01-.18-.44V3.13zM30 32.52H2.52V12.5H30v20z"
			/>
		</svg>
	)
}
