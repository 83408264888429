import React, { useContext } from 'react'
import './EventItem.scss'
import Eur from '../../images/icons/Eur'
import Calendar from '../../images/icons/Calendar'
import { Link } from 'gatsby'
import { animated } from 'react-spring'
import { HeaderContext } from '../Header/Header'

export default ({ event, style }) => {
	if (!event) return null
	const { period, price, to, name, type } = event
	const HeaderCTX = useContext(HeaderContext)

	return (
		<animated.li className={'event__list-item'} style={{ ...style }}>
			<p className={'event__list-item--title'}>{name}</p>
			<div className={'event__list-item--meta-holder'}>
				{period && !period.includes('null') && period.length > 3 && (
					<>
						<Calendar winter={HeaderCTX.winterTime} />
						<div>
							<p className={'event__list-item--meta-title'}>
								START
							</p>
							<time dateTime={period}>{period}</time>
						</div>
					</>
				)}
			</div>
			<div className={'event__list-item--meta-holder'}>
				<Eur />
				<div>
					<p className={'event__list-item--meta-title'}>PRIJS</p>
					<data className="money" value={price}>
						{price && price !== 'null' ? price : 'In overleg'}
					</data>
				</div>
			</div>
			<Link
				className={'event__list-item--link link__underline--secondary'}
				to={to.replace('//', '/')}
			>
				{type ? type : 'Activiteit'} bekijken
			</Link>
		</animated.li>
	)
}
