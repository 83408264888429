import React, { useContext } from 'react'
import './EventItemFilter.scss'
import EventItemFilterButton from '../EventItemFilterButton/EventItemFilterButton'
import EventItemList from '../EventItemList/EventItemList'
import Title from '../Title/Title'
import { HeaderContext } from '../Header/Header'

const arrayToSortBy = [
	'alles',
	'volwassenen',
	'midjeugd(13 - 16)',
	'jeugd(6 - 12)',
	'ouder - kind cursussen',
	'schaatsen',
	'skeeleren',
	'mountainbiken',
	'droogtrainen',
	'vakantieclinics',
	'privélessen',
	'scholen',
	'partijtjes',
	'bedrijfsevenementen',
	'sportvakantie',
	'overig',
]

export default function EventItemFilter({ events }) {
	const HeaderCTX = useContext(HeaderContext)

	const dataObject = {
		alles: events,
	}

	if (!events) return null
	events.forEach((event) => {
		if (event === undefined) return
		const category =
			event.category !== undefined
				? event.category.toLowerCase()
				: 'Activiteit'
		//Its an array
		if (category.includes(',')) {
			const multipleEvents = category.split(',')
			multipleEvents.forEach((singularCategory) => {
				dataObject[singularCategory] === undefined
					? (dataObject[singularCategory] = [event])
					: dataObject[singularCategory].push(event)
			})
		} else {
			dataObject[category] === undefined
				? (dataObject[category] = [event])
				: dataObject[category].push(event)
		}
	})

	const sortedByCategoryEvents = Object.keys(dataObject)
		.sort((a, b) => arrayToSortBy.indexOf(a) - arrayToSortBy.indexOf(b))
		.reduce((obj, key) => {
			obj[key] = dataObject[key]
			return obj
		}, {})

	// We need to disable a button when it is once clicked.
	const updateEvents = (e) => {
		const text = e.target
			? e.target.textContent.toLowerCase()
			: e.toLowerCase()

		Object.keys(sortedByCategoryEvents).forEach((categoryName) => {
			const category = categoryName.toLowerCase()

			if (
				category === text &&
				category !== HeaderCTX.currentActiveEventItem
			) {
				HeaderCTX.updateActiveEventItem(() => categoryName)
			}
		})
	}

	return (
		<section className={'event__filter'} id={'aanbod'}>
			<Title heading={2} text={'Onze cursussen'} />
			<div className={'event__button--list'} role={'tablist'}>
				{Object.keys(sortedByCategoryEvents).length
					? Object.keys(sortedByCategoryEvents).map((event) => (
							<EventItemFilterButton
								category={event}
								action={(e) => updateEvents(e)}
								active={HeaderCTX.currentActiveEventItem}
								key={event}
							/>
					  ))
					: null}
			</div>

			<EventItemList
				events={
					sortedByCategoryEvents[HeaderCTX.currentActiveEventItem]
				}
				category={HeaderCTX.currentActiveEventItem}
			/>
		</section>
	)
}
