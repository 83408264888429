import React from 'react'
import './TextInColumns.scss'
import Title from '../Title/Title'

export default ({ content }) => {
	return (
		<section className={'textcolumn'}>
			{content.length
				? content.map((column) => {
						return (
							<article
								key={column.title}
								className={'textcolumn__column'}
							>
								<Title
									className={'textcolumn__column--title'}
									heading={2}
									text={column.title}
								/>
								<div className={'textcolumn__column--text'}>
									{column.content}
								</div>
							</article>
						)
				  })
				: null}
		</section>
	)
}
