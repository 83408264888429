import React, { useContext } from 'react'
import { HeaderContext } from '../../components/Header/Header'

export default (props) => {
	const HeaderCTX = useContext(HeaderContext)

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 28 36"
			stroke="none"
			strokeWidth="0"
		>
			<path
				fill={
					props.color
						? props.color
						: HeaderCTX.winterTime
						? '#005394'
						: 'rgba(234, 134, 45, 1)'
				}
				fillRule="evenodd"
				d="M16.47 35.24c-8.32 0-12.81-4.06-12.81-11.4v-.2H.96v-4.9h2.7v-1.86H.96v-4.9h2.7v-.35C3.66 4.73 8.15.22 16.47.22c4.04 0 7.93 1.32 11.18 3.58l-3.1 5.38c-3.09-1.42-5.13-2.05-7.93-2.05-3.04 0-4.88 1.71-4.88 4.5v.35h8.03v4.9h-8.03v1.86h8.03v4.9h-8.03v.2c0 2.78 1.84 4.5 4.88 4.5 2.75 0 4.74-.54 8.03-2.06l3.15 5.3c-3.7 2.34-7.04 3.66-11.33 3.66z"
			/>
		</svg>
	)
}
